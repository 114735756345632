<template>
  <div>
    <v-container
      ><p>
        Write the chemical symbol of the element that is located in the described position, and
        determine its physical state of matter under standard conditions.
      </p></v-container
    >
    <v-simple-table>
      <tbody>
        <tr v-for="(question, index) in questions" :key="question">
          <td>{{ question }} {{ Groups[index] }}</td>
          <td>
            <v-text-field
              v-model="inputs[`pair${index + 1}`][0]"
              class="element"
              @keypress="checkInput($event)"
            />
          </td>
          <td>Its state is:</td>
          <td>
            <v-select v-model="inputs[`pair${index + 1}`][1]" class="group" :items="stateOptions">
            </v-select>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
export default {
  name: 'IdentifyElementDetermineState',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        pair1: [null, null],
        pair2: [null, null],
        pair3: [null, null],
        pair4: [null, null],
        pair5: [null, null],
      },
      questions: [
        'a) Period 2, Group ',
        'b) Period 3, Group ',
        'c) Period 6, Group ',
        'd) Period 4, Group ',
        'e) Period 5, Group ',
      ],
      stateOptions: ['solid', 'liquid', 'gas'],
    };
  },
  computed: {
    Groups() {
      //had to use 1-8 instead of 1, 2, 3, 4, 5, 6, 7, 0 because of the min and max on number variables
      let group1 = this.taskState.getValueBySymbol('group1').content.number;
      let group2 = this.taskState.getValueBySymbol('group2').content.number;
      let group3 = 12;
      let group4 = this.taskState.getValueBySymbol('group4').content.number;
      let group5 = this.taskState.getValueBySymbol('group5').content.number;
      return [group1, group2, group3, group4, group5];
    },
  },
  methods: {
    checkInput(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z]+$/.test(char)) {
        return true;
      }
      e.preventDefault();
    },
  },
};
</script>
<style>
.group {
  width: 80px;
}
.element {
  width: 40px;
}
</style>
